<template>
  <div>
    <v-dialog v-model="innerValue" temporary scrollable>
      <v-card>
        <v-card-title class="grey lighten-4" primary-title>
          <app-id-icon />
          <span class="font-weight-bold tertiary--text title">
            {{ $t("global.file") }} {{ $t("buttons.preview") }}
          </span>
          <v-spacer />
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" color="grey" icon @click="innerValue = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("buttons.close") }}</span>
          </v-tooltip>
        </v-card-title>
        <v-card-text class="pa-0">
          <embed
            :src="fileUrl"
            type="application/pdf"
            frameBorder="0"
            scrolling="auto"
            style="height: 80vh; width: 100%"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: "PdfViewerDialog",
    props: { value: Boolean, fileUrl: String },
    data: () => ({}),
    computed: {
      innerValue: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit("input", value);
        }
      }
    }
  };
</script>
